import React, { memo } from 'react';

import Head from 'next/head';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';

const Headers = ({ seo }) => {
  const router = useRouter();

  return (
    <Head>
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      <title>{seo.title || 'Printi. | Mais que uma Gráfica Online'}</title>
      <meta
        name="description"
        content={
          seo.description ||
          'A Printi é um modelo inovador de gráfica online com impressão digital e offset de alta qualidade a preços acessíveis.'
        }
      />
      <meta name="robots" content="noindex" />
      <meta name="copyright" content={seo.copyright || 'Printi'} />
      <meta
        name="abstract"
        content={
          seo.abstract ||
          seo.description ||
          'A Printi é um modelo inovador de gráfica online com impressão digital e offset de alta qualidade a preços acessíveis.'
        }
      />
      <meta name="author" content={seo.author || 'Printi Tech'} />
      <meta name="application-name" content={seo.applicationName || 'Printi'} />
      <meta name="apple-mobile-web-app-title" content={seo.mobileAppTitle || 'Printi'} />
      <meta name="msapplication-TileColor" content="#2b5797" />
      <meta name="msapplication-TileImage" content="/static/favicon/mstile-144x144.png" />
      <meta name="msapplication-square70x70logo" content="/static/favicon/mstile-70x70.png" />
      <meta name="msapplication-square150x150logo" content="/static/favicon/mstile-150x150.png" />
      <meta name="msapplication-wide310x150logo" content="/static/favicon/mstile-310x150.png" />
      <meta name="msapplication-square310x310logo" content="/static/favicon/mstile-310x310.png" />
      <meta name="theme-color" content="#ffffff" />

      <meta property="og:url" content={`https://www.printi.com.br${router.asPath}`.split('?')[0]} />
      <meta property="og:type" content={seo.type || 'website'} />
      <meta property="og:locale" content="pt-BR" />
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta
        property="og:image"
        content={seo.image || 'https://www.printi.com.br/assets/final/img/logos-printi/Printi-simbolo.png'}
      />
      <meta property="og:image:width" content="200" />
      <meta property="og:image:height" content="267" />
      <meta property="fb:admins" content="627008" />
      <meta property="fb:app_id" content="312936488776247" />

      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:description" content={seo.description} />
      <meta
        name="twitter:image"
        content={seo.image || 'https://www.printi.com.br/assets/final/img/logos-printi/Printi-simbolo.png'}
      />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content="Printi" />

      <link rel="canonical" href={`https://www.printi.com.br${router.asPath}`.split('?')[0]} />

      <link rel="apple-touch-icon" sizes="57x57" href="/static/favicon/apple-touch-icon-57x57.png" />
      <link rel="apple-touch-icon" sizes="60x60" href="/static/favicon/apple-touch-icon-60x60.png" />
      <link rel="apple-touch-icon" sizes="72x72" href="/static/favicon/apple-touch-icon-72x72.png" />
      <link rel="apple-touch-icon" sizes="76x76" href="/static/favicon/apple-touch-icon-76x76.png" />
      <link rel="apple-touch-icon" sizes="114x114" href="/static/favicon/apple-touch-icon-114x114.png" />
      <link rel="apple-touch-icon" sizes="120x120" href="/static/favicon/apple-touch-icon-120x120.png" />
      <link rel="apple-touch-icon" sizes="144x144" href="/static/favicon/apple-touch-icon-144x144.png" />
      <link rel="apple-touch-icon" sizes="152x152" href="/static/favicon/apple-touch-icon-152x152.png" />
      <link rel="shortcut icon" href="/static/favicon/favicon.ico" />
      <link rel="icon" href="/static/favicon/favicon-16x16.png" sizes="16x16" />
      <link rel="icon" href="/static/favicon/favicon-32x32.png" sizes="32x32" />
      <link rel="icon" href="/static/favicon/favicon-194x194.png" sizes="194x194" />
      <link rel="icon" type="image/png" sizes="192x192" href="android-chrome-192x192.png" />
      <link rel="manifest" href="/static/favicon/manifest.json" />
      <link rel="mask-icon" href="/static/favicon/safari-pinned-tab.svg" color="#5bbad5" />
    </Head>
  );
};

Headers.propTypes = {
  seo: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    copyright: PropTypes.string,
    abstract: PropTypes.string,
    robots: PropTypes.string,
    type: PropTypes.string,
    image: PropTypes.string,
    author: PropTypes.string,
    applicationName: PropTypes.string,
    mobileAppTitle: PropTypes.string,
  }),
};

export default memo(Headers);
